/* font-family: "Poppins", sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/**===================== App CSS Start ==========================**/
@tailwind base;
@tailwind components;
@tailwind utilities;

/**===================== App CSS End ==========================**/
body {
    font-family: "Poppins", sans-serif;
    background: #ededf5;
}

.container {
    max-width: 100%;
}
.custom-grid{
    display: grid;
    grid-template-columns: repeat(16, 1fr);
}
.left-after{
    position: relative;
}
.left-after::after{
    position: absolute;
    border-top: 1px solid #000;
    content: "";
    top: 0;
    right: 0;
    width: 50%;
    border-left: 1px solid #000;
    height: 25px;
}
.right-after{
    position: relative;
}
.right-after::after{
    position: absolute;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    content: "";
    top: 0;
    left: 0;
    width: 50%;
    height: 25px;
}
.border-after{
    position: relative;
}
.border-after:before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    border-left: 1px solid #000;
    width: 0;
    height: 25px;
}

div.dt-container select.dt-input{
    padding: 0;
}
.badge{
    display: inline-block;
    padding: .75em;
    font-size: 100%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.50rem;
}
.badge-danger {
    color: #fff;
    background-color: #DC3545;
}
.custom-thead {
    text-align: start !important;
  }
@media (max-width:768px){
    .dt-info ,.dt-length .dt-input,.dt-length label{
        font-size: 14px;
        line-height: 16px;
    }    
}